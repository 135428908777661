import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const SiteLink = ({ children, to }) => {
	return (
		<Link to={to} className="no-underline text-primary hover:primary-dark hover:font-bold">
			{children}
		</Link>
	);
};

SiteLink.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SiteLink;

import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";
import SiteLink from "../../components/SiteLink";

const title = "Diverticulosis And Diverticulitis";

// Download link - http://www.fbrennangastro.com.au/downloads/gesadiverticulardisease.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[
				title,
				"diverticulosis",
				"diverticulitis",
				"diagnosis",
				"symptoms",
				"toilet straining",
				"constipation",
			]}
			type="article"
			description="Diverticulosis and diverticulitis, the differences between the two, causes, symptoms, how to diagnose via a colonoscopy,"
		/>
		<H1>{title}</H1>
		<Par>
			Many people have small pouches in their colons that bulge outwards through weak spots, like an inner tube that
			pokes through weak places in a tyre. Each pouch is called a diverticulum and when multiple pouches are present in
			the large bowel the condition is called diverticulosis. The condition becomes more common as people age and about
			half of all people over the age of 60 have diverticulosis. The condition is frequently found at colonoscopy and is
			not usually associated with any symptoms.
		</Par>
		<Par>
			When the pouches become infected or inflamed, the condition is called diverticulitis. This will happen to
			approximately 10% of people with diverticulosis. Diverticulosis and diverticulitis are also called diverticular
			disease.
		</Par>
		<h2 className="mt-3">What are the symptoms?</h2>
		<Par>
			<b>Diverticulosis</b> - most people with diverticulosis do not have any discomfort or symptoms. Occasionally
			symptoms may include mild cramps, bloating and <SiteLink to="/articles/constipation">constipation</SiteLink> but
			other diseases such as irritable bowel syndrome (IBS) are a much more common cause of these symptoms.
		</Par>
		<Par>
			<b>Diverticulitis</b> - the most common symptom of diverticulitis is abdominal pain which classically occurs in
			the left side of the lower abdomen but it may be in the centre of the lower abdomen and rarely on the right side
			of the lower abdomen. If infection is the cause then fever, nausea, vomiting, chills, cramping and constipation
			may occur as well. The severity of symptoms depends on the extent of the infection and complications.
		</Par>
		<h2 className="mt-3">What causes diverticulosis?</h2>
		<Par>
			The dominant theory is that a low fibre diet is the main cause of diverticular disease. This disease is common in
			developed and industrialised countries such as Australia, USA and the UK where low fibre diets are common. The
			disease is rare in countries such as Asia and Africa where people eat high fibre vegetable diets. Fibre is the
			part of the fruits, vegetables and grains that the body cannot digest. Some fibre dissolves easily in water
			(soluble fibre) where some fibre passes almost unchanged through the intestine (insoluble fibre). Both kinds of
			fibre help to make the stool soft and easy to pass and therefore help to prevent constipation. Constipation makes
			the muscles strain to move stool that is too hard and it is one of the main causes of increased pressure in the
			colon. Excess pressure may cause weak spots in the colon to bulge out and become diverticuli.
		</Par>
		<h2 className="mt-3">What causes diverticulitis?</h2>
		<Par>
			Diverticulitis occurs when diverticula become infected or inflamed. The exact cause is uncertain and it may begin
			when stool or bacteria are caught in the diverticula. Some people relate certain attacks to dietary indiscretion
			such as consuming nuts or foods with seeds or pips but there is no consistent evidence that certain foods
			precipitate attacks.
		</Par>
		<h2 className="mt-3">How is diverticulosis diagnosed?</h2>
		<Par>
			To confirm diverticulosis some form of imaging or endoscopic examination is usually required. As most people do
			not have symptoms, diverticulosis is often diagnosed through tests ordered for another ailment, e.g. rectal
			bleeding.
		</Par>
		<h2 className="mt-3">How is diverticulitis diagnosed?</h2>
		<Par>
			As mentioned diverticulitis is a complication of diverticulosis and occurs when diverticula become infected or
			inflamed. The infection/inflammation in the bowel will frequently cause a rise in CRP (an inflammatory marker
			found in the blood) and if pain is severe then a CT scan carried out on a new generation scanner will help.
			Ideally this scan should be reviewed by an experienced abdominal radiologist where the tell tale signs, i.e.
			streaking of the mesentery will be observed. This examination also has the advantage of ruling out other
			complications such as abscess formation or perforation.
		</Par>
		<h2 className="mt-3">Are there other complications of diverticulosis apart from infection?</h2>
		<ul>
			<li className="mt-3">
				<b>Bleeding</b>
				<br />
				This is a rare complication but is usually dramatic when it occurs. The patient frequently describes sitting on
				the toilet and blood appears as if a &apos;tap was turned on&apos;. As this symptom is often dramatic patients
				frequently seek emergency attention and usually urgent hospital admission is required. It is believed that
				bleeding occurs when a small blood vessel in a diverticula weakens and bursts. The vast majority of this type of
				bleeding settles spontaneously but occasionally an emergency colonoscopy with sclerotherapy (injection into the
				bleeding vessel)or surgery may be necessary.
			</li>
			<li className="mt-3">
				<b>Sepsis</b>
				<br />
				Perforation and peritonitis: while infection causing diverticulitis will usually present with pain occasionally
				symptoms are mild and ignored by the patient until their general condition deteriorates. This may mean an
				abscess is formed. An abscess is an infected area with pus which may cause swelling and destroy tissue. As
				tissues are destroyed, perforations will form and this may allow pus to leak out of the colon into the abdominal
				area. Small abscesses may respond to conservative treatment with intravenous antibiotics, bed rest and a liquid
				or low residue diet. Large abscesses may require surgery. If a large abscesses ruptures prior to hospital
				admission then an emergency operation may be called for.
			</li>
			<li className="mt-3">
				<b>Fistula</b>
				<br />
				This is an abnormal connection between the tissue of two organs. This occurs when infected tissue comes into
				contact with another organ within the abdomen causing them to stick together. If they heal that way then a
				fistula may form. The organs usually involved are the bladder, small intestine and occasionally the vagina. This
				complication will usually require surgery.
			</li>
			<li className="mt-3">
				<b>Intestinal obstruction </b>
				<br />
				Scarring caused by infection may lead to a partial or total blockage of the large intestine. When this happens
				the colon is unable to move bowel contents normally. If the obstruction is total then emergency surgery may be
				necessary. If the blockage is only partial then surgery can be carried out on a more planned basis.
			</li>
		</ul>
		<h2 className="mt-3">Treatment of diverticulitis</h2>
		<Par>
			Generally the treatment will be aimed at clearing up infection and inflammation, resting the colon and preventing
			or minimising complications. An attack of diverticulitis without complications may respond to antibiotics within a
			few days if treated early. Recommended antibiotics include Amoxycillin, Keflex, Metronidazole and Augmentin.
		</Par>
		<Par>If the attack is severe then hospital admission may be required for intravenous antibiotics.</Par>
		<h2 className="mt-3">When is surgery necessary?</h2>
		<Par>
			Most patients will only suffer from one attack and it is rare for these to recur. If the attacks are severe or
			frequent or associated with significant complications such as abscess formation then surgery may be advised. If
			patients have a well documented attack of diverticulitis before the age of 55, i.e. with raised CRP or CT scan
			changes then surgery may be advised even after a single attack. In these patients there is a concern that attacks
			will recur, particularly in later life when their fitness to undergo surgery may be less than optimal. The surgery
			usually involves a sigmoid resection, the affected part of the colon is removed and the remaining sections are
			joined back together. This aims to keep attacks from recurring and to prevent complications.
		</Par>
		<Par>
			Surgery may also be recommended for significant complications, e.g. large abscess, fistula or obstruction.
		</Par>
	</Blog>
);
